import axios from 'axios';
import { createStore } from 'vuex'
import { Post, State, User } from './ApiTypes'
import * as sha256 from 'sha256'

export default createStore({
  state: <State>{
    user: <any>null,
    stripe_api_key: process.env.VUE_APP_STRIPE_API_KEY,
    pageScroll:true

  },
  getters: {
  },
  mutations: {
    set_user(state, payload) {
      state.user = payload;
    },
    set_page(state, payload) {
      state.page = payload;
    },
    set_post(state, payload) {
      if (!state.page) return;
      state.page.posts[payload.slot] = payload.post;
    },
    set_isPageScroll(state, payload){
      state.pageScroll = payload.scroll;
    }
  },
  actions: {
    /**
     * only to be called if already logged in
     * @param param0 
     */
    async getUser({ dispatch, commit }) {
      let res = await axios.get("/auth/user");
      if (res.status == 200) {
        commit("set_user", res.data);
      }
      return res.status;
    },
    async login({ dispatch, commit }, { username, password }) {
      let res = await axios.post("/auth/local", { username, password });

      if (res.status == 200)
        commit("set_user", res.data)
      return res.status;
    },
    async logout({ dispatch, commit }) {
      let res = await axios.post("/auth/logout");
      commit("set_user", null);
      return res.status;
    },
    async getPage({ dispatch, commit }, { pagePath }) {
      let res = await axios.get(`/board/guest/${pagePath}`);
      commit("set_page", res.data);
      return res.status;
    },
    async updatePost({ dispatch, commit }, { post, mediaUrl }) {

      let file = await fetch(mediaUrl).then(r => r.blob());
      let buffer =  [... new Uint8Array(await file.arrayBuffer())];
      let hash = sha256.default(buffer);
      
    
      let res = await axios.post(`/board/updatepost/${post.id}`, {
        post, mediaHash:hash
      });

      if (res.status != 200) return res;


      let uploadUrl = res.data.uploadUrl;
      let fields = res.data.fields;

      if (uploadUrl && fields) {
        let data = new FormData();
        

        for (let f in fields) {
          data.append(f, fields[f]);
        }
        data.append('Content-Type', file.type);
        data.append('file', file);


        try {
          let res = await fetch(uploadUrl, {
            method: 'POST',
            body: data,
          });
          console.log(await res.text());
        } catch (e) { console.log(e) }

        //notify server, media has been uploaded

        let tp: any = await axios.put("/board/uploaded/" + res.data.user_post.id);
        res.data.user_post.media = tp.data.media;
        console.log(tp.media);

        

      }




      commit("set_post", { slot: post.slot, post: res.data.user_post })
      return res;
    },


  },
  modules: {
  }
})
