
import router from "@/router";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { api_path } from "../main"

@Options({
    components: {},
})
export default class PageBrowserView extends Vue {

    public all_pages: { id: number, path: string }[] = [];
    public active_pages: { id: number, path: string, visits: number, count: number }[] = [];

    public board_input = "";

    public sort_type: "alpha" | "views" = "views"


    async mounted() {
        let res = await axios.get("/board/guest/boards");
        if (res.data) {
            this.all_pages = res.data.all_pages;
            this.active_pages = res.data.active_pages;
        }
        this.sort("views");
    }

    public gotoBoard(path?: string) {
        if (path) {
            router.push("/board/" + path)
        } else if (this.board_input) {
            router.push("/board/" + this.board_input)
        }
    }

    public sort(sort_type: "alpha" | "views") {
        this.sort_type = sort_type;

        if (sort_type == "alpha")
            this.active_pages.sort((p1, p2) => { return p1.path.localeCompare(p2.path) });
        else
            this.active_pages.sort((p1, p2) => { return p2.visits - p1.visits });

    }

}
