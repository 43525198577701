
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  computed: {
    pageScroll() {
      return this.$store.state.pageScroll;
    }
  },
  watch: {
    pageScroll(n, o) {
      if (!n) {
        document.getElementsByTagName("HTML")[0].setAttribute("style", "overflow-y: hidden;")
      } else {
        document.getElementsByTagName("HTML")[0].setAttribute("style", "overflow-y: overflow;")
      }

    }
  }
})



export default class App extends Vue {

  public expanded = false;

  mounted() {
    this.$store.dispatch("getUser");
  }

  public async logout() {
    await this.$store.dispatch("logout");
    this.$router.push("/login")
  }
}
