
import router from "@/router";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import {api_path} from "../main"

@Options({
  components: {},
})
export default class LoginView extends Vue {
  public type: "login" | "new" = "login";
  public username: string = "";
  public password: string = "";
  public verify_password: string = "";
  public email: string = "";
  public error: string = "";
  public success: string = "";
  public loading:boolean = false;
  public api_path = api_path;

  public tab_login() {
    this.clearStatusMessage();
    this.type = "login";
  }
  public tab_create_account() {
    this.clearStatusMessage();
    this.type = "new";
  }

  public clearStatusMessage(){
    this.error="";
    this.success="";
  }

  public async login() {
    this.clearStatusMessage();
    this.loading = true;
    if (this.username == "" || this.password == "") {
      this.error = "username and password must have a value";
      this.loading = false;
      return;
    }

    
    await this.$store.dispatch("login", {
      username: this.username,
      password: this.password,
    });
    this.loading = false;

    if (!this.$store.state.user) this.error = "failed to login";
    else{ 
      this.success = "Successfully logged in!"  
      setTimeout(()=>{
        this.$router.push("/boards");
      }, 1000);
    }
  }


  public async loginWith(name:String){
    if(name=="google"){
      document.location.href = api_path + "/auth/google";
      //let res = await axios.get("/auth/google");
      
    }
  }


  public async createAccount() {
    this.clearStatusMessage();
    this.loading = true;
    if(this.username == "" || this.password=="" || this.email=="" || this.verify_password == ""){
      this.error = "Please fill in all required values."
      this.loading = false;
      return;
    }

    if(this.password != this.verify_password){
      this.error = "passwords must match";
      this.loading = false;
      return;
    }

    let res = await axios.post(`/auth/signup`, {
      password:this.password,
      username : this.username,
      firstname : "", 
      lastname : "",
      email : this.email
    });

    this.loading = false;

    if(res.status != 200){
      this.error = res.data
      return;
    }

    this.success = "Successfully created a new account! Please Login"
    
    setTimeout(()=>{
      this.type = "login";
    }, 1000)
  
  }
}
