

import { Post } from '@/store/ApiTypes';
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';
import TimeAgo from 'javascript-time-ago'
import StripePayment from './StripePayment.vue'

@Options({
    components: {

    },
    props: {
        exp: Date,
        force_expanded:Boolean

    }
})
export default class LockIndicator extends Vue {
    public exp!: Date
    public force_expanded!:boolean;
    public timeLeft = "";
    public mouse_over = false;
    
    private interval:any = null;
    public mounted(){
        if(this.force_expanded == null) this.force_expanded =false;
        this.interval = setInterval(()=>{
            let seconds = (this.exp.getTime() - new Date().getTime())/1000;
            
            let hour = Math.trunc(seconds/60/60);
            seconds -= hour * 60 * 60;
            let minute = Math.trunc(seconds/60);
            seconds -= minute * 60;
            seconds = Math.trunc(seconds)
            
            this.timeLeft = `${hour}h ${minute}m ${seconds}s`
        }, 1000)
    }

    public unmounted(){
        clearInterval(this.interval);
    }

    public get hover(){
        return this.force_expanded || this.mouse_over
    }

}


