
import { Options, Vue } from 'vue-class-component';
import axios, { Axios } from 'axios';
import PostComponent from '../components/PostComponent.vue';
import { Page } from '@/store/ApiTypes';

@Options({
  components: {
    PostComponent: PostComponent
  },
})



export default class HomeView extends Vue {
  public page_name: string = "";

  public loading = true;

  public editing = -1;
  public editing_content = "";
  public editing_title = "";

  public copied = false;
  private copied_interval = -1;

  public get page(): Page {
    //@ts-ignore
    return this.$store.state.page;
  }

  public async mounted() {
    this.page_name = <string>this.$route.params.page;

    await this.loadPage();
    this.loading = false;
  }

  public async loadPage() {
    await this.$store.dispatch("getPage", { pagePath: this.page_name });
  }


  public click_share(){
    this.copyTextToClipboard(document.location.href)
    this.copied = true;
    
    clearTimeout(this.copied_interval);
    this.copied_interval = <any> setTimeout(()=>{
      this.copied = false;
    }, 2000)
  }
    

  public fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
  public copyTextToClipboard(text:string) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }


}
