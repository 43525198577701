
import { Post } from '@/store/ApiTypes';
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ExpandedPost from './ExpandedPost.vue'
import LockIndicator from './LockIndicator.vue'

@Options({
  props: {
    slot: Number,
    static_post: {}

  },
  components: {
    ExpandedPost: ExpandedPost,
    LockIndicator: LockIndicator
  },
})
export default class PostComponent extends Vue {
  slot!: number
  static_post!: Post


  public expanded = false;

  public post_history: Post[] = [];

  public modalSelection: "like" | "comment" | "edit" = "edit"

  public is_lock_hover: boolean = false;

  get post_status() {
    let exp = 0;

    if (this.post.lock_expiration_time)
      exp = new Date(this.post.lock_expiration_time + "").getTime();

    if (exp > new Date().getTime()) return "locked"
    else return "unlocked"
  }

  get post() {

    if (this.static_post) {
      return this.static_post;
    }

    if (this.$store.state.page)
      return this.$store.state.page?.posts[this.slot]
    else
      return <Post>{}

  }

  get isNew():boolean{
    return new Date().getTime() - new Date(this.post.post_time + "").getTime() < 1000*60*60*6
  }

  get postTime(): string {
    return <string>this.ago(this.post.post_time)
  }

  get lock_expiration() {
    return new Date(this.post.lock_expiration_time + "");
  }

  public ago(ps_time: any) {
    return new TimeAgo('en-US').format(new Date(ps_time + ""), 'mini')
  }

  public expand(to: "like" | "comment" | "edit" = "edit") {
    this.modalSelection = to;
    this.expanded = true;
    this.$store.commit("set_isPageScroll", { scroll: false })
  }
  public collapse() {
    this.expanded = false;
    this.$store.commit("set_isPageScroll", { scroll: true })
  }


}

