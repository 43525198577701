
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import { Post } from '@/store/ApiTypes';

@Options({
    props: {
        post:{},
        blobHash:String
    }
})
export default class StripePayment extends Vue {
    post!:Post
    blobHash!:string

    public stripe: any;
    public elements: any;
    public isLoading = false;
    public message = "";

    public postError = "";

    public async mounted() {
        this.stripe = (<any>window).Stripe(this.$store.state.stripe_api_key);

        let res = await axios.post("/payment/purchase/post", {
            post: this.post, 
            hash: this.blobHash
        });

        this.$.emit('mount', {uploadUrl:res.data.uploadUrl, fields:res.data.fields});
        
         if (res.status >= 400 || res.status < 200) {
            if (res.data == "post locked") {
                //post is locked
                this.postError = res.data;
            } else {
                //missing values
                this.postError = res.data;
            }
            return;
        }


        let client_secret = res.data.clientSecret;

        this.elements = this.stripe.elements({ appearance: { theme: 'stripe' }, clientSecret: client_secret });
        this.elements.create("payment").mount("#payment-element")

    }


    public async pay() {
        this.isLoading = true;

        const { error } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
                return_url: window.location.href
            }
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            this.showMessage(error.message);
        } else {
            this.showMessage("An unexpected error occured.");
        }



        this.isLoading = false;
    }

    public showMessage(messageText: string) {
        this.message = messageText;
        let self = this;
        setTimeout(function () {
            self.message = "";
        }, 4000);
    }

    public async checkStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
            case "succeeded":
                this.showMessage("Payment succeeded!");
                break;
            case "processing":
                this.showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                this.showMessage("Your payment was not successful, please try again.");
                break;
            default:
                this.showMessage("Something went wrong.");
                break;
        }
    }


}
