
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import PostComponent from '../components/PostComponent.vue'; // @ is an alias to /src
import { Post } from '@/store/ApiTypes';
import router from '@/router';

@Options({
  components: {
    PostComponent: PostComponent
  },
})
export default class HomeView extends Vue {

  public post1: Post = {
    authorusername: "Ekle Enterprise",
    title: "This is a post",
    content: "You can replace it by editing it!",
    id: -1,
    lock_expiration_time: new Date(),
    lock_iteration: 0,
    media: "/img/default_post.jpg",
    post_time: "5/22/2022",
    slot: -1,
    unlock_price: 100,
    commentcount: 0
  }

  public post2: Post = {
    authorusername: "Ekle Enterprise",
    title: "Locks",
    content: "You can pay to lock a post, or to take someone else's spot. A lock expires after 6 hours. Hover over the lock to see when the lock expires.",
    id: -1,
    lock_expiration_time: new Date(new Date().getTime() + 6 * 1000 * 60 * 60),
    lock_iteration: 0,
    media: "/img/default_post.jpg",
    post_time: "5/22/2022",
    slot: -1,
    unlock_price: 100 * (2 ** 1),
    commentcount: 0
  }

  public goto_boardBrowser() {
    router.push("/boards");
  }

}
