import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios, { Axios } from 'axios';
import TimeAgo from 'javascript-time-ago'

//@ts-ignore literally added to the config file.. idk what its problem is here
import en from 'javascript-time-ago/locale/en.json'




TimeAgo.addDefaultLocale(en);

export let api_proto = process.env.VUE_APP_API_PROTO;
export let api_domain = process.env.VUE_APP_API_DOMAIN;
export let api_path = process.env.VUE_APP_API_PATH || "";

axios.interceptors.request.use((config) => {
  config.url = api_path + config.url;
  config.withCredentials = true;
  config.validateStatus= () => true;
  return config;
});
axios.interceptors.response.use(
  (res) => {
    
    if (res.status == 401) {
      console.log("access denied")
      //access denied, prompt login
      console.log(res.config);
      if(!res.config.url?.endsWith("/auth/user")){
        router.push("/login")
      }
      
      
    }
    return res;
  },
  (error) => {
    return error.response
  })



createApp(App).use(store).use(router).mount('#app')
