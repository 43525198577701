

import { Post } from '@/store/ApiTypes';
import axios, { Axios, AxiosResponse } from 'axios';
import { Options, Vue } from 'vue-class-component';
import TimeAgo from 'javascript-time-ago'
import StripePayment from './StripePayment.vue'
import LockIndicator from './LockIndicator.vue'

import * as Filepond from 'filepond';
import * as sha256 from 'sha256';
import CommentSection from './CommentSection.vue';


@Options({
    components: {
        StripePayment,
        LockIndicator,
        CommentSection

    },
    props: {
        slot: Number,
        openTo: String,
        static_post: {}

    }
})
export default class ExpandedPost extends Vue {
    slot!: number
    openTo!: string

    public editing: boolean = false;
    public editing_content = "";
    public editing_title = "";
    public editing_media = "";

    public blobUrl = "";
    public blobHash = "";

    public expanded = false;

    public post_history: Post[] = [];

    public modalSelection: "like" | "comment" | "edit" = "edit"

    public showPayment = false;
    public postError = "";

    public static_post!: Post;

    public uploadId = "a" + Math.floor(Math.random() * 1000000000000000000);

    public isMobile = false;


    public resize() {
        this.isMobile = this.calcIsMobile();
    }

    public calcIsMobile() {
        if (document.body.clientWidth <= 760) {
            return true;
        }
        else {
            return false;
        }
    }


    get post() {
        if (this.static_post) return this.static_post;

        if (this.$store.state.page)
            return this.$store.state.page?.posts[this.slot]
        else
            return <Post>{}

    }

    get mediaEditingUrl() {
        if (this.editing_media.startsWith("u-")) {
            return this.editing_media.slice(2);
        }
        return this.editing_media
    }

    get post_status() {
        let exp = 0;

        if (this.post.lock_expiration_time)
            exp = new Date(this.post.lock_expiration_time + "").getTime();

        if (exp > new Date().getTime()) return "locked"
        else return "unlocked"
    }

    get updated_post() {
        let pc: Post = JSON.parse(JSON.stringify(this.post));
        pc.title = this.editing_title;
        pc.media = this.editing_media;
        pc.content = this.editing_content;
        return pc;
    }


    get postTime(): string {
        return <string>this.ago(this.post.post_time)
    }

    get lock_expiration() {
        return new Date(this.post.lock_expiration_time + "");
    }

    get lock_cost() {

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(this.post.unlock_price / 100)
    }



    public mounted() {
        switch (this.openTo) {
            case 'edit':
                this.click_edit();
                break;
            case 'comment':
                this.click_comments();
                break;
            case 'like':
                this.click_likes();
                break;
            default:
                this.click_comments();
                break;

        }

        window.addEventListener('resize', this.resize);
        //this.click_edit();
    }

    public unmounted() {
        window.removeEventListener('resize', this.resize);
    }

    public clear_blob(event?: Event) {
        this.blobUrl = "";
        this.editing_media = "";

        if (event)
            event.preventDefault();
    }

    public clear_edit() {
        this.clear_blob();
        this.editing_content = "";
        this.editing_media = "";
        this.editing_title = ""
    }



    public async stripeMount({ uploadUrl, fields }: { uploadUrl: string, fields: any }) {

        let file = await fetch(this.blobUrl).then(r => r.blob());
        if (uploadUrl && fields) {
            let data = new FormData();


            for (let f in fields) {
                data.append(f, fields[f]);
            }
            data.append('Content-Type', file.type);
            data.append('file', file);


            try {
                let res = await fetch(uploadUrl, {
                    method: 'POST',
                    body: data,
                });
                console.log(await res.text());
            } catch (e) { console.log(e) }

            //notify server, media has been uploaded

            //let tp: any = await axios.put("/board/uploaded/" + res.data.user_post.id);


        }

    }

    public async previewFiles(event: Event) {

        //@ts-ignore
        let file: File = event.target.files[0];

        if (file.size > 1000000) {
            this.postError = "Max Image Size: 1MB"
            return;
        }

        this.blobUrl = URL.createObjectURL(file);
        this.editing_media = "u-" + file.name;

        let file2 = await fetch(this.blobUrl).then(r => r.blob());
        let buffer = [... new Uint8Array(await file2.arrayBuffer())];
        let hash = sha256.default(buffer);
        this.blobHash = hash;
    }

    public ago(ps_time: any) {
        return new TimeAgo('en-US').format(new Date(ps_time + ""), 'mini')
    }

    public cancelPayment() {
        this.showPayment = false;
    }


    public begin_edit() {
        this.editing = true;
        this.editing_title = this.post.title
        this.editing_content = this.post.content
    }

    public async save_edit() {
        if (this.static_post) return;
        let pc: Post = JSON.parse(JSON.stringify(this.post));
        pc.title = this.editing_title;
        pc.media = this.editing_media;
        pc.content = this.editing_content;

        let file: Blob = await fetch(this.blobUrl).then(r => r.blob());
        if (file.size > 1000000) {
            this.postError = "Max Image Size: 1MB"
            return;
        }

        /*
        let dimen = await new Promise((resolve, reject) => {
            let img = document.createElement('img');
            img.onload = () => {
                resolve([img.width, img.height])
            }
            img.src = this.blobUrl
        })
        */


        let res: AxiosResponse = await this.$store.dispatch("updatePost",
            { post: pc, mediaUrl: this.blobUrl });


        if (res.status >= 400 || res.status < 200) {

            if (res.data == "post locked") {
                //post is locked
                this.postError = res.data;
            } else {
                //missing values
                this.postError = res.data;
            }
        } else {
            this.clear_edit();
            this.postError = "";
            this.editing = false;
            await this.getPostHistory();
        }





    }

    public async getPostHistory() {
        if (this.static_post) return;
        let res = await axios.get(`/board/guest/posthistory/${this.$store.state.page?.id}/${this.slot}`);
        this.post_history = res.data;
    }

    public async getLikes() {

    }

    public async getComments() {

    }


    public lock_post() {
        if (this.static_post) return;
        //show stripe prompt and wait for server message        
        this.showPayment = true;
        //this.showPayment = false;
    }


    public async click_comments() {
        this.modalSelection = "comment";
        this.clear_edit();
    }

    public async click_edit() {
        this.modalSelection = "edit";
        await this.getPostHistory();
    }

    public async click_likes() {
        this.modalSelection = "like";
        this.clear_edit();

    }



    public async postComment() {

    }


}


