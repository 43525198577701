
import { Post } from '@/store/ApiTypes';
import axios from 'axios';
import TimeAgo from 'javascript-time-ago';
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        slot: Number
    }
})
export default class HelloWorld extends Vue {
    slot!: number
    public content: string = "";

    public comments: { id: number, postid: number, content: string, comment_time: Date, authorid: number, username: string }[] = [];

    /*
    get postTime(): string {
        return <string>this.ago(this.post.post_time)
    }*/

    public ago(ps_time: any) {
        return new TimeAgo('en-US').format(new Date(ps_time + ""), 'mini')
    }

    get post(): Post {
        if (this.$store.state.page)
            return this.$store.state.page?.posts[this.slot]
        else
            return <Post>{}
    }

    public async mounted() {
        this.getComments();



    }

    public async getComments() {
        let res = await axios.get(`/board/guest/comment/${this.post.id}/-1/20`)
        if (res.status != 200) return;
        //this.comments.push(res.data[0])
        this.comments = res.data;

    }

    public async loadMore(){
        console.log(this.comments[this.comments.length-1].id);
        let lastId = this.comments[this.comments.length-1].id;
        let res = await axios.get(`/board/guest/comment/${this.post.id}/${lastId}/20`)
        for(let c of res.data){
            this.comments.push(c);
        }
    }

    public async submit() {
        let res = await axios.post(`/board/comment/${this.post.id}`, {
            content: this.content
        });
        this.content = "";
        await this.getComments();
        this.post.commentcount++;
    }

}
